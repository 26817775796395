import { useState, useRef } from "react";
import {motion } from 'framer-motion';
import { slideIn } from "../utils/motion";
import { FaTelegram } from "react-icons/fa";
import {AiFillTwitterCircle} from "react-icons/ai"
const Hero = () => {


    const [isCopied, setIsCopied] = useState(false);
    const handleCopied = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 5000)
    }

    const contractRef = useRef();

    const copyContractText = () => {
        const text = contractRef.current.textContent;
        navigator.clipboard.writeText(text);
        console.log('Text copied: ' + text);
        handleCopied();
    };


    return (<div className="w-full  flex flex-col justify-center items-center pt-5 md:pt-10 gap-10">
        {/* <h1 className="font-bold tracking-wider text-[80px]">$Google</h1> */}
        <div className=" mt-0 md:mt-5 w-[80%] md:w-[60%]"><img src="../img/logo-2.png" alt="logo" /></div>

        <div onClick={copyContractText}  className="rounded-full w-full md:w-[80%] border-[2px] border-[#4285F4] cursor-pointer flex flex-col items-center justify-center gap-2 p-3">
         
  
            <h1  ref={contractRef} className='text-[#4285F4] md:text-[25px] font-bold'  >{!isCopied?'Contract incoming':'Copied!'}</h1>
        </div>
        <div className="flex flex-row gap-3">
            <motion.a
           
            href="https://t.me/sol_googlecoin"><button className="flex flex-row items-center gap-1 border-[1px] bg-[#4285F4] hover:bg-[#275fba] text-[#fff] rounded-lg py-2 duration-300 px-4"> <FaTelegram /> Telegram</button></motion.a>
            <motion.a 

            href="https://twitter.com/solgooglecoin"><button className="border-[1px] bg-[#4285F4] hover:bg-[#275fba] text-[#fff] rounded-lg flex flex-row gap-1 items-center py-2 duration-300 px-4"> <AiFillTwitterCircle /> Twitter/X</button></motion.a>
        </div>
        <motion.p
       
        className="w-full font-semibold md:w-[55%] text-center"><span className="hover:text-[#275fba] font-bold text-[#4285F4] text-[18px]cursor-pointer">$Google</span> is an independent and innovative cryptocurrency project inspired by the remarkable achievements of the popularly known Google.

        </motion.p>
        <motion.p
         
        className="w-full text-[#DB4437] md:w-[50%] text-center ">Disclaimer: We don’t have any connection or affiliation with the following website or company - <a href="https://www.google.com/" className="underline" style={{fontFamily:"'Open Sans', sans-serif "}}>google</a></motion.p>
    </div>);
}

export default Hero;