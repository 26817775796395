import { motion } from 'framer-motion';
import { slideIn } from "../utils/motion";
const Header = () => {
    const socialData = [
        {
            imgUrl: "social-1",
            link: 'https://t.me/telegram'
        },
        {
            imgUrl: "social-4",
            link: 'https://uniswap.com'
        },
        {
            imgUrl: "social-5",
            link: 'https://coinmarketcap.com'
        },
        {
            imgUrl: "social-2",
            link: 'https://coingecko.com'
        },
        {
            imgUrl: "social-3",
            link: 'https://twitter.com'
        },

    ]
    return (<motion.div className=" bg-gradient-to-l from-[#18E6AD] to-[#A754EA] w-full text-[#fff] uppercase md:mb-10 text-[18px]  
     p-4 flex flex-row gap-5 md:gap-20 justify-center items-center mb-10"
        variants={slideIn('up', 'spring', 0.1, 0.4)}
        initial="hidden"
        whileInView="visible"
        
    >
        <a className="hover:underline" href="https://www.dextools.io/app/ether/pair-explorer/#">Home</a>
        <a className="hover:underline" href="#tokenomics">dexscreener</a>
        <a className="hover:underline" href="#">Buy $Google</a>
    </motion.div>);
}

export default Header;