import { useEffect } from 'react';
import { useState } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Footer, Header, Hero } from './components';
import Home from './pages/Home';
function App() {
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  useEffect(() => {

    const innerH = window.innerHeight;
    const innerW = window.innerWidth;
    setHeight(innerH);
    setWidth(innerW);


  }, []);
  console.log(height, width);

  return (
    <Router>
      <Header />
      <Switch>
        <div className="App">
          <Route exact path="/">
            <Home
              divHeight={height}
              divWidth={width}
            />
          </Route>
        </div>
      </Switch>

    </Router>
  );
}

export default App;
