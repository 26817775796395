import { Hero, Buy, WhyBob, RoadMap } from "../components";
import { useState } from "react";
import {motion} from 'framer-motion'
import { slideIn } from "../utils/motion";
import ScrollToTopButton from "../components/ScrollToTopBar";

const Home = ({ divHeight, divWidth }) => {

    function scrollToDiv() {
        const myDiv = document.getElementById('#Tokenomics');
        myDiv.scrollIntoView({ behavior: 'smooth' });
    }

    const [viewToken, setViewToken] = useState();
    const [showMenu, setShowMenu] = useState(false);
    const handleMenu = () => {
        setShowMenu(prevState => !prevState);
    }

    return (<div className=" relative flex flex-col px-4 pt-4 w-[90%] md:w-[70%] mx-auto items-center justify-center ">
        <div style={{ height: divWidth >= 1024 ? divHeight : '' }}>
            <Hero /> 
        </div>
       
    </div>);
}

export default Home;